import React from "freedomjs";
import { Component } from "freedomjs";

export class NavItem extends Component {
    render() {
        return (
            <a
                href={this.props.href}
                class={
                    this.props.hash.startsWith(this.props.href)
                        ? "flex flex-align-center text-dark mt-05 rounded uppercase p-1 primary-hover active"
                        : "flex flex-align-center text-dark mt-05 rounded uppercase p-1 primary-hover"
                }>
                <i class="material-icons mr-05">{this.props.icon}</i>
                {this.props.text}
            </a>
        );
    }
}
