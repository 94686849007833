import React from "freedomjs";
import { Component } from "freedomjs";

export class TimeText extends Component {
    render() {
        console.log("time text: " + this.props.value);
        if (this.props.value == null) {
            return this.children.length ? (
                this.children
            ) : (
                <div class="lds-ring">
                    <div></div>
                </div>
            );
        }

        const seconds = this.props.value;
        const minutes = seconds / 60;
        const remaining = seconds % 60;

        return Math.round(minutes) + "m " + Math.round(remaining) + "s";
    }
}
