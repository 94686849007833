import React from "freedomjs";
import { Component } from "freedomjs";
import { Cognito } from "./cognito";
import { USERS_URL } from "./constants";

export class EmployeeList extends Component {
    constructor(o) {
        super(o, { items: [] });
    }

    async fetchUsers() {
        const token = await Cognito.idToken;

        const response = await fetch(USERS_URL, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "text/plain",
                Authorization: token,
            },
            body: JSON.stringify({
                name: "result",
                company: "careindeed",
            }),
        });
        const result = await response.json();
        if (result.success) {
            this.setState({
                items: Object.keys(result.data).sort((a, b) => {
                    return parseInt(a) - parseInt(b);
                }),
            });
        }
    }

    onPropsChanged(oldState, newState) {
        if (oldState.hash !== newState.hash || oldState.isSignedIn !== newState.isSignedIn) {
            if (newState.hash.startsWith("#/employees") && newState.isSignedIn) {
                this.fetchUsers();
            }
        }
    }

    render() {
        const items = this.state.items;
        const rows = [];
        items.forEach((i) => {
            if (this.props.search) {
                if (!i.includes(this.props.search)) {
                    return;
                }
            }
            rows.push(<employee class="flex flex-column light rounded mb-1 ml-1 mr-1 p-1 z-1" user={i} sessions={[]} view={false} />);
        });
        return rows.length == 0 ? (
            <div class="flex flex-grow-1 flex-align-center flex-justify-center">
                <div class="lds-ring">
                    <div></div>
                </div>
            </div>
        ) : (
            rows
        );
    }
}
