import React from "freedomjs";
import { Component } from "freedomjs";
import { STATS_URL } from "./constants";
import { Cognito } from "./cognito";

export class Dashboard extends Component {
    constructor(o) {
        super(o, {
            stats: { scores: null, firstTries: {}, secondTries: {}, thirdTries: {}, avgTimeSpent: {} },
        });
    }

    async fetchData() {
        const token = await Cognito.idToken;

        const response = await fetch(STATS_URL, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "text/plain",
                Authorization: token,
            },
            body: JSON.stringify({
                name: "result",
                company: "careindeed",
            }),
        });
        const result = await response.json();
        if (result.success) {
            this.setState({
                stats: result.data,
            });
        }
    }

    onPropsChanged(oldState, newState) {
        if (oldState.isSignedIn !== newState.isSignedIn || oldState.hash !== newState.hash) {
            if (newState.hash.startsWith("#/dashboard") && newState.isSignedIn) {
                this.fetchData();
            }
        }
    }

    render() {
        return [
            <h1 class="p-1 m-1">Dashboard</h1>,
            <div class="flex flex-row grid">
                <div class="relative flex-grow-1 light rounded p-1 mt-1 ml-1 mr-1 z-1">
                    {this.state.stats.scores ? (
                        <scores
                            hash={this.props.hash}
                            class="flex rounded p-1 yellow z-1"
                            style="margin-top: -32px;"
                            stats={this.state.stats.scores}
                            simulation="Simulation1"></scores>
                    ) : (
                        ""
                    )}
                    <h2 class="p-1 border-bottom-light">Simulation1 Scores</h2>
                    <div class="flex flex-align-center p-1">
                        <i class="material-icons mr-05">access_time</i> updated now
                    </div>
                </div>
                <div class="relative flex-grow-1 light rounded p-1 mt-1 ml-1 mr-1 mt-1 z-1">
                    {this.state.stats.scores ? (
                        <scores
                            class="flex rounded p-1 secondary z-1"
                            style="margin-top: -32px;"
                            stats={this.state.stats.scores}
                            simulation="Simulation2"></scores>
                    ) : (
                        ""
                    )}
                    <h2 class="p-1 border-bottom-light">Simulation2 Scores</h2>
                    <div class="flex flex-align-center p-1">
                        <i class="material-icons mr-05">access_time</i> updated now
                    </div>
                </div>
            </div>,
            <div class="flex flex-row grid"></div>,
            <div class="flex flex-row mb-1 grid">
                <div class="relative flex-grow-1 light rounded p-1 mt-1 ml-1 mr-1 z-1">
                    <averages
                        class="flex flex-grow-1 flex-align-center mt-1 ml-1 mr-1"
                        stats={this.state.stats.avgTimeSpent}
                        simulation="Simulation1">
                        <i class="material-icons text-primary text-display">access_time</i>
                        <timetext class="text-display text-primary"></timetext>
                    </averages>
                    <h2 class="p-1 border-bottom-light">Avg Time Spent Simulation1</h2>
                    <div class="flex flex-align-center p-1">
                        <i class="material-icons mr-05">access_time</i> updated now
                    </div>
                </div>
                <div class="relative flex-grow-1 light rounded p-1 mt-1 ml-1 mr-1 z-1">
                    <averages
                        class="flex flex-align-center flex-grow-1 mt-1 ml-1 mr-1"
                        stats={this.state.stats.avgTimeSpent}
                        simulation="Simulation2">
                        <i class="material-icons text-primary text-display">access_time</i>
                        <timetext class="text-display text-primary"></timetext>
                    </averages>
                    <h2 class="p-1 border-bottom-light">Avg Time Spent Simulation2</h2>
                    <div class="flex flex-align-center p-1">
                        <i class="material-icons mr-05">access_time</i> updated now
                    </div>
                </div>
            </div>,
        ];
    }
}
