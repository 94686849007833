import React from "freedomjs";
import { Component } from "freedomjs";
import { Cognito } from "./cognito";
import { LIST_URL } from "./constants";

export class QuestionTimes extends Component {
    createChart() {
        let values = [];
        let labels = [];

        const sim = this.props.questions;

        const tempDiv = document.createElement("div");
        tempDiv.className = "ct-chart flex-grow-1";

        if (!sim) {
            return tempDiv;
        }

        labels = sim.map((v) => {
            return v.id;
        });
        values = sim.map((v) => {
            return v.time;
        });

        const chart = Chartist.Bar(
            tempDiv,
            {
                labels: labels,
                series: [values],
            },
            {
                axisY: {
                    onlyInteger: true,
                },
                low: 0,
                height: 256,
                width: "100%",
                seriesBarDistance: 5,
                sync: true,
            }
        );
        chart.init(true);
        setTimeout(() => {
            if (tempDiv.__chartist__) {
                tempDiv.__chartist__.update();
            }
        }, 1);
        return tempDiv;
    }

    componentDidMount() {
        if (this.dom.children[0].__chartist__) {
            this.dom.children[0].__chartist__.enableResize();
        }
    }

    componentWillUnmount() {
        if (this.dom.children[0].__chartist__) {
            this.dom.children[0].__chartist__.disableResize();
        }
    }

    render() {
        return this.createChart();
    }
}

export class QuestionTries extends Component {
    createChart() {
        let values = [];
        let labels = [];

        const sim = this.props.questions;

        const tempDiv = document.createElement("div");
        tempDiv.className = "ct-chart flex-grow-1";

        if (sim) {
            labels = sim.map((v) => {
                return v.id;
            });
            values = sim.map((v) => {
                return v.incorrect + 1;
            });
        }

        const chart = Chartist.Bar(
            tempDiv,
            {
                labels: labels,
                series: [values],
            },
            {
                axisY: {
                    onlyInteger: true,
                },
                low: 0,
                height: 256,
                width: "100%",
                seriesBarDistance: 5,
                sync: true,
            }
        );
        chart.init(true);
        setTimeout(() => {
            if (tempDiv.__chartist__) {
                tempDiv.__chartist__.update();
            }
        }, 1);
        return tempDiv;
    }

    //chartist workarounds sadly
    //this chartist library has been modified
    //so it is sync

    // Work around for chartistjs so it properly updates
    // on hide / unhide of dom elements
    onPropsChanged() {
        if (this.dom.children[0].__chartist__) {
            setTimeout(() => {
                this.dom.children[0].__chartist__.disableResize();
                this.dom.children[0].__chartist__.update();
                this.dom.children[0].__chartist__.enableResize();
            }, 1);
        }
    }

    componentDidMount() {
        if (this.dom.children[0].__chartist__) {
            this.dom.children[0].__chartist__.enableResize();
        }
    }

    componentWillUnmount() {
        if (this.dom.children[0].__chartist__) {
            this.dom.children[0].__chartist__.disableResize();
        }
    }

    render() {
        return this.createChart();
    }
}

export class Session extends Component {
    render() {
        const date = this.props.session.data.date;
        return (
            <div class="flex flex-column">
                <div class="flex flex-row flex-align-center mb-1">
                    <div class="flex flex-column mr-1">
                        <h2 class="pl-1">{this.props.session.data.simulation}</h2>
                        <small class="pl-1 mb-1">{this.props.session.session}</small>
                    </div>
                    <div class="p-1 green text-large rounded ml-1 mr-1">{Math.round(this.props.session.data.score * 100)} of 100</div>
                    <div class="p-1 dark text-large rounded mr-1">
                        First Try -{" "}
                        {
                            this.props.session.data.questions.filter((v) => {
                                return v.incorrect === 0;
                            }).length
                        }
                    </div>
                    <div class="p-1 gray text-large rounded mr-1">
                        Second Try -{" "}
                        {
                            this.props.session.data.questions.filter((v) => {
                                return v.incorrect === 1;
                            }).length
                        }
                    </div>
                    <div class="p-1 light-gray text-large rounded mr-1">
                        Third+ Try -{" "}
                        {
                            this.props.session.data.questions.filter((v) => {
                                return v.incorrect === 2;
                            }).length
                        }
                    </div>
                </div>

                <div class="flex flex-row grid mb-1">
                    <div class="relative flex-grow-1 light rounded p-1 mt-1 ml-1 mr-1 z-1">
                        <questiontries
                            class="flex rounded p-1 yellow z-1"
                            style="margin-top: -32px;"
                            questions={this.props.session.data.questions}></questiontries>
                        <h3 class="p-1 border-bottom-light">Tries</h3>
                        <div class="flex flex-align-center p-1">
                            <i class="material-icons mr-05">access_time</i>
                            {new Date(date ? date : Date.now()).toLocaleString()}
                        </div>
                    </div>
                    <div class="relative flex-grow-1 light rounded p-1 mt-1 ml-1 mr-1 mt-1 z-1">
                        <questiontimes
                            class="flex rounded p-1 secondary z-1"
                            style="margin-top: -32px;"
                            questions={this.props.session.data.questions}></questiontimes>
                        <h3 class="p-1 border-bottom-light">Time (in seconds)</h3>
                        <div class="flex flex-align-center p-1">
                            <i class="material-icons mr-05">access_time</i>
                            {new Date(date ? date : Date.now()).toLocaleString()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export class Employee extends Component {
    async fetchSessions() {
        const token = await Cognito.idToken;

        const response = await fetch(LIST_URL, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "text/plain",
                Authorization: token,
            },
            body: JSON.stringify({
                name: "result",
                company: "careindeed",
                user: this.props.user,
            }),
        });

        const result = await response.json();
        if (result.success) {
            this.setProps({
                sessions: result.data,
            });
        }
    }

    onViewResults(e) {
        this.setProps({
            view: !this.props.view,
        });

        if (!this.props.sessions.length) {
            this.fetchSessions();
        }
    }

    render() {
        const sessions = this.props.sessions;
        const rows = [];
        sessions.forEach((s) => {
            rows.push(<session class="mt-1" session={s} />);
        });
        return [
            <div class="flex flex-row flex-align-center text-large">
                {this.props.user}
                <div class="flex flex-row flex-grow-1 flex-justify-right">
                    <button class="uppercase primary text-light rounded p-1 pointer" onclick="onViewResults">
                        {this.props.view ? "hide results" : "view results"}
                    </button>
                </div>
            </div>,
            <div class={this.props.view ? "flex flex-column" : "flex flex-column hidden"}>
                {rows.length === 0 ? (
                    <div class="lds-ring">
                        <div></div>
                    </div>
                ) : (
                    rows
                )}
            </div>,
        ];
    }
}
