import { Component } from "freedomjs";

export class ExportNotif extends Component {
    renderProps() {
        return {
            h3: {
                textContent: this.props.showExport && this.props.value ? "Export Ready" : "Export Status",
            },
            p: {
                style: this.props.showExport && !this.props.value ? "" : "display: none;",
            },
            a: {
                style: this.props.showExport && this.props.value ? "" : "display: none;",
                href: this.props.value,
            },
            'div[class="lds-ring"]': {
                style: !this.props.exporting ? "display: none;" : "",
            },
        };
    }

    onDownload() {
        setTimeout(() => {
            if (this.props.finish) {
                this.props.finish();
            }
        }, 250);
    }
}
