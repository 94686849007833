import { Component } from "freedomjs";
import { Cognito } from "./cognito";

export class Signin extends Component {
    constructor(o) {
        super(o, { previousStage: 1, stage: 1, email: "", password: "", newPassword: "", confirmPassword: "", verificationCode: "", verify: false });
    }

    async onSubmit(e) {
        e.preventDefault();

        if (this.state.stage == 1) {
            try {
                await Cognito.signIn(this.state.email, this.state.password, (cb) => {});
                this.setState({
                    password: "",
                    email: "",
                });

                if (window.location.hash.startsWith("#/")) {
                    window.location.reload();
                } else {
                    window.location.hash = "#/dashboard";
                }
            } catch (e) {
                console.log(e);
                if (e === "new password required") {
                    console.log("need new first password");
                    this.setState({
                        stage: 2,
                        password: "",
                        verify: false,
                    });
                    // note add this to myndweb / myfamily to handle reset password etc
                } else if (e.code && e.code.toLowerCase().includes("passwordreset")) {
                    this.setState({
                        stage: 2,
                        password: "",
                        verify: true,
                    });
                } else {
                    this.setState({
                        stage: 3,
                        previousStage: 1,
                        password: "",
                    });
                }
            }
            6;
        } else if (this.state.stage == 2) {
            try {
                if (this.state.confirmPassword !== this.state.newPassword) {
                    return;
                }

                //we add one extra reg check for symbols only as the previous reg can return true if
                //such is provided: aB1b. As the regex sometimes ignores counting the \D\W aka non digits and non letters.
                if (
                    !this.state.newPassword.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\D\W])[A-z\d\D\W]{4,}/g) ||
                    !this.state.newPassword.match(/[\D\W]{1,}/g)
                ) {
                    return;
                }

                if (this.state.verify) {
                    await Cognito.confirmPassword(this.state.verificationCode, this.state.newPassword);
                } else {
                    await Cognito.completeNewPasswordChallenge(this.state.newPassword);
                }
                this.setState({
                    email: "",
                    password: "",
                    newPassword: "",
                    confirmPassword: "",
                    verficationCode: "",
                    verify: false,
                });
                if (window.location.hash.startsWith("#/")) {
                    window.location.reload();
                } else {
                    window.location.hash = "#/dashboard";
                }
            } catch (e) {
                //note add better error message handling here for the user
                console.log(e);
            }
        }
    }

    onTryAgain(e) {
        e.preventDefault();

        this.setState({
            stage: this.state.previousStage,
        });
    }

    onKeyPress(e) {
        if (e.key == "Enter") {
            this.onSubmit(e);
        }
    }

    onEmailInput(e) {
        this.setState({
            email: e.target.value,
        });
    }

    onPasswordInput(e) {
        this.setState({
            password: e.target.value,
        });
    }

    onNewPasswordInput(e) {
        this.setState({
            newPassword: e.target.value,
        });
    }

    onVerificationInput(e) {
        this.setState({
            verificationCode: e.target.value,
        });
    }

    onConfirmInput(e) {
        this.setState({
            confirmPassword: e.target.value,
        });

        if (this.state.confirmPassword !== this.state.newPassword) {
            e.target.setCustomValidity("password must match");
        } else {
            e.target.setCustomValidity("");
        }
    }

    renderProps() {
        return {
            stage1: {
                style: this.state.stage === 1 ? "" : "display: none;",
                'input[id="email"]': {
                    value: this.state.email,
                },
                'input[id="password"]': {
                    value: this.state.password,
                },
            },
            stage2: {
                style: this.state.stage === 2 ? "" : "display: none;",
                form: {
                    'input[id="newPassword"]': {
                        value: this.state.newPassword,
                    },
                    'input[id="confirmPassword"]': {
                        value: this.state.confirmPassword,
                    },
                    'input[id="verificationCode"]': {
                        style: this.state.verify ? "" : "display: none;",
                        value: this.state.verificationCode,
                    },
                },
            },
            stage3: {
                style: this.state.stage === 3 ? "" : "display: none;",
            },
        };
    }
}
