import { Component } from "freedomjs";

export class Nav extends Component {
    renderProps() {
        return {
            navitem: {
                hash: this.props.hash,
            },
        };
    }
}
