import { Component } from "freedomjs";
import React from "freedomjs";

export class Scores extends Component {
    createChart() {
        const values = [];
        const labels = [];

        const tempDiv = document.createElement("div");
        tempDiv.className = "ct-chart flex-grow-1";

        const sim1 = this.props.stats[this.props.simulation];
        if (sim1) {
            for (let k in sim1) {
                values.push(sim1[k]);
                labels.push(k);
            }
        }

        const chart = Chartist.Bar(
            tempDiv,
            {
                labels: labels,
                series: [values],
            },
            {
                axisY: {
                    onlyInteger: true,
                },
                low: 0,
                height: 256,
                width: "100%",
                sync: true,
            }
        );
        chart.init(true);
        setTimeout(() => {
            if (tempDiv.__chartist__) {
                tempDiv.__chartist__.update();
            }
        }, 10);
        return tempDiv;
    }

    // Work around for chartistjs so it properly updates
    // on hide / unhide of dom elements
    onPropsChanged() {
        if (this.dom.children[0].__chartist__) {
            setTimeout(() => {
                this.dom.children[0].__chartist__.disableResize();
                this.dom.children[0].__chartist__.update();
                this.dom.children[0].__chartist__.enableResize();
            }, 1);
        }
    }

    componentDidMount() {
        if (this.dom.children[0].__chartist__) {
            this.dom.children[0].__chartist__.enableResize();
        }
    }

    componentWillUnmount() {
        if (this.dom.children[0].__chartist__) {
            this.dom.children[0].__chartist__.disableResize();
        }
    }

    render() {
        return this.createChart();
    }
}
