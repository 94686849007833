import "./app.css";
import { Root } from "./root";
import { Signin } from "./signin";
import { Dashboard } from "./dashboard";
import { TimeText } from "./timetext";
import { Averages } from "./averages";
import { Nav } from "./nav";
import { Scores } from "./scores";
import { NavItem } from "./navitem";
import { EmployeeList } from "./employeelist";
import { Employee, Session, QuestionTries, QuestionTimes } from "./employee";
import { Employees } from "./employees";
import { ExportNotif } from "./exportnotif";

export default function App() {
    //export your components to the window here
    //e.g. window.calculator = Calculator;
    window.root = Root;
    window.signin = Signin;
    window.dashboard = Dashboard;
    window.timetext = TimeText;
    window.averages = Averages;
    window.nav = Nav;
    window.scores = Scores;
    window.navitem = NavItem;
    window.employeelist = EmployeeList;
    window.employee = Employee;
    window.employees = Employees;
    window.session = Session;
    window.questiontries = QuestionTries;
    window.questiontimes = QuestionTimes;
    window.exportnotif = ExportNotif;
}
