import { Component } from "freedomjs";

export class Averages extends Component {
    renderProps() {
        return {
            timetext: {
                value: this.props.stats[this.props.simulation],
            },
        };
    }
}
