import { Component } from "freedomjs";
import { Cognito } from "./cognito";

export class Root extends Component {
    constructor(o) {
        super(o, { hash: window.location.hash, isSignedIn: false });
    }

    onHashChanged(e) {
        if (window.location.hash == "#/signout") {
            Cognito.globalSignout();
            this._render();
            return;
        }

        if (!Cognito.isSignedIn) {
            Cognito.refreshToken();
            return;
        }

        this.setState({
            hash: window.location.hash,
        });
    }

    async checkSignin() {
        Cognito.sessionCallback = () => {
            if (window.location.hash.startsWith("#/")) {
                this._render();
            } else {
                window.location.hash = "#/dashboard";
            }
        };

        try {
            await Cognito.sessionData;
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {
        window.addEventListener("hashchange", this.onHashChanged.bind(this));
        this.checkSignin();
    }

    renderProps() {
        return {
            div: {
                nav: {
                    style: Cognito.isSignedIn ? "" : "display: none;",
                    isSignedIn: Cognito.isSignedIn,
                    hash: this.state.hash,
                },
            },
            signin: {
                style: Cognito.isSignedIn ? "display: none;" : "",
                isSignedIn: Cognito.isSignedIn,
            },
            dashboard: {
                style: Cognito.isSignedIn && this.state.hash.startsWith("#/dashboard") ? "" : "display: none;",
                isSignedIn: Cognito.isSignedIn,
                hash: this.state.hash,
            },
            employees: {
                style: Cognito.isSignedIn && this.state.hash.startsWith("#/employees") ? "" : "display: none;",
                isSignedIn: Cognito.isSignedIn,
                hash: this.state.hash,
            },
        };
    }
}
