import { Component } from "freedomjs";
import { Cognito } from "./cognito";
import { EXPORT_URL } from "./constants";

export class Employees extends Component {
    constructor(o) {
        super(o, { search: "", startDate: null, endDate: null, exporting: false, showExport: false, exportUrl: null });
    }

    renderProps() {
        return {
            employeelist: {
                isSignedIn: this.props.isSignedIn,
                search: this.state.search,
                hash: this.props.hash,
            },
            exportnotif: {
                showExport: this.state.showExport,
                exporting: this.state.exporting,
                value: this.state.exportUrl,
                style: this.state.showExport || this.state.exporting ? "" : "display: none;",
                finish: () => {
                    this.setState({
                        showExport: false,
                        exporting: false,
                        exportUrl: null,
                    });
                },
            },
        };
    }

    onStartDateInput(e) {
        try {
            let value = e.target.value ? new Date(e.target.value).getTime() : null;

            if (typeof value !== "number" || Number.isNaN(value) || !Number.isFinite(value)) {
                value = null;
            }

            console.log(value);

            this.setState({
                startDate: value,
            });
        } catch (e) {
            console.log(e);
            this.setState({
                startDate: null,
            });
        }
    }

    onEndDateInput(e) {
        try {
            let value = e.target.value ? new Date(e.target.value).getTime() : null;

            if (typeof value !== "number" || Number.isNaN(value) || !Number.isFinite(value)) {
                value = null;
            }

            console.log(value);

            this.setState({
                endDate: value,
            });
        } catch (e) {
            console.log(e);
            this.setState({
                endDate: null,
            });
        }
    }

    async sendExport(startDate, endDate) {
        const token = await Cognito.idToken;

        const response = await fetch(EXPORT_URL, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "text/plain",
                Authorization: token,
            },
            body: JSON.stringify({
                name: "result",
                company: "careindeed",
                startDate: startDate,
                endDate: endDate,
            }),
        });

        const result = await response.json();
        if (result.success) {
            this.setState({
                showExport: true,
                exporting: false,
                exportUrl: result.data,
            });
        } else {
            this.setState({
                showExport: true,
                exporting: false,
                exportUrl: null,
            });

            setTimeout(() => {
                this.setState({
                    showExport: false,
                    exporting: false,
                    exportUrl: null,
                });
            }, 2000);
        }
    }

    onExport(e) {
        if (!this.state.exporting) {
            this.setState({
                showExport: false,
                exporting: true,
            });

            this.sendExport(this.state.startDate, this.state.endDate);
        }
    }

    onSearchInput(e) {
        this.setState({
            search: e.target.value,
        });
    }
}
